import {
  createStyles,
  Divider,
  makeStyles,
  Table,
  TableBody,
  TableContainer,
  TableFooter,
  TableHead,
  TableRow,
  Theme,
} from "@material-ui/core"
import * as React from "react"
import { formatAmount } from "../../../utils/format"
import { PaycheckTableCell } from "../elements/sidebar"

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    quoteDatesSmall: {
      color: theme.palette.primary.main,
    },
    quoteDateSpan: {
      color: theme.palette.secondary.dark,
    },
  }),
)

interface ISideBarTableProps {
  quoteData: { quote: any; quoteLoading: boolean; quoteMessage: string }
}

const SideBarTable: React.FunctionComponent<ISideBarTableProps> = ({
  quoteData,
  ...props
}) => {
  const classes = useStyles()
  const companyName = process.env.COMPANY_NAME || "paycheck"
  return (
    <p>
      You requested <strong>R{quoteData.quote.advanceAmount}</strong>. The total
      amount to be deducted from your salary on{" "}
      <strong>{quoteData.quote.salaryDeductionDate}</strong> is{" "}
      <strong>
        R
        {formatAmount(
          quoteData.quote.advanceAmount +
            quoteData.quote.transactionFee +
            quoteData.quote.bankTransfer,
        )}
      </strong>
      , which includes a discounting fee of{" "}
      <strong>
        R
        {formatAmount(
          quoteData.quote.transactionFee + quoteData.quote.bankTransfer,
        )}
      </strong>
      .
    </p>

    // <TableContainer>
    //   <Table aria-label="simple table">
    //     <TableHead>
    //       <TableRow>
    //         <PaycheckTableCell align="left" colSpan={2}>
    //           Amount Requested
    //         </PaycheckTableCell>
    //         <PaycheckTableCell align="right">
    //           R{formatAmount(quoteData.quote.advanceAmount)}
    //         </PaycheckTableCell>
    //       </TableRow>
    //     </TableHead>
    //     <TableBody>
    //       <TableRow>
    //         <PaycheckTableCell>&nbsp;</PaycheckTableCell>
    //         <PaycheckTableCell align="left">Transaction Fee</PaycheckTableCell>
    //         <PaycheckTableCell align="right">
    //           +R{formatAmount(quoteData.quote.transactionFee)}
    //         </PaycheckTableCell>
    //       </TableRow>
    //       <TableRow>
    //         <PaycheckTableCell>&nbsp;</PaycheckTableCell>
    //         <PaycheckTableCell align="left">Bank Charge</PaycheckTableCell>
    //         <PaycheckTableCell align="right">
    //           +R{formatAmount(quoteData.quote.bankTransfer)}
    //         </PaycheckTableCell>
    //       </TableRow>
    //       <TableRow>
    //         <PaycheckTableCell colSpan={3}>&nbsp;</PaycheckTableCell>
    //       </TableRow>
    //       <TableRow>
    //         <PaycheckTableCell colSpan={3}>
    //           <small className={classes.quoteDatesSmall}>
    //             Request Date:{" "}
    //             <span className={classes.quoteDateSpan}>
    //               {quoteData.quote.requestDate}
    //             </span>
    //           </small>
    //         </PaycheckTableCell>
    //       </TableRow>
    //       <TableRow>
    //         <PaycheckTableCell colSpan={3}>
    //           <small className={classes.quoteDatesSmall}>
    //             Your{" "}
    //             <span style={{ textTransform: "capitalize" }}>
    //               {companyName}
    //             </span>{" "}
    //             will be available in your bank account on{" "}
    //             <span className={classes.quoteDateSpan}>
    //               {quoteData.quote.actionDate}
    //             </span>
    //           </small>
    //         </PaycheckTableCell>
    //       </TableRow>
    //       <TableRow>
    //         <PaycheckTableCell colSpan={3}>
    //           <small className={classes.quoteDatesSmall}>
    //             Salary Deduction Date:{" "}
    //             <span className={classes.quoteDateSpan}>
    //               {quoteData.quote.salaryDeductionDate}
    //             </span>
    //           </small>
    //         </PaycheckTableCell>
    //       </TableRow>
    //       <TableRow>
    //         <PaycheckTableCell colSpan={3}>
    //           <Divider />
    //         </PaycheckTableCell>
    //       </TableRow>
    //     </TableBody>
    //     <TableFooter>
    //       <TableRow>
    //         <PaycheckTableCell align="left" colSpan={2}>
    //           Total Deduction From Payroll
    //         </PaycheckTableCell>
    //         <PaycheckTableCell align="right">
    //           R
    //           {formatAmount(
    //             quoteData.quote.advanceAmount +
    //               quoteData.quote.transactionFee +
    //               quoteData.quote.bankTransfer,
    //           )}
    //         </PaycheckTableCell>
    //       </TableRow>
    //     </TableFooter>
    //   </Table>
    // </TableContainer>
  )
}

export default SideBarTable
